@import '../../scss/variables.scss';

.container {
	border-radius: $border-radius;
	border: 1px solid rgba(0,0,0,.4);
	padding: $spacing-base;
	display: flex;
	justify-content: center;
	align-items: center;
}

.disabled {
	cursor: not-allowed;
}