@import '../../scss/variables';


.container {
	padding: 0.5rem;
	overflow: hidden;
}

.label {
	font-weight: bold;
	margin-bottom: 0.2rem;
}

.wrapper {
	background-color: rgba(255,255,255,0.4);
	padding: 0.4rem;
	margin-bottom: 1rem;
	border-radius: $border-radius;
	font-size: 1.1em;
}

.company {
	margin-bottom: 0.5rem;
}

.shippingMethod {
	margin-top: 0.5rem;
	opacity: 0.5;
}
