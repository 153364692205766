@import '../../scss/variables';

.overlay {
    background-color: rgba(0, 0, 0, .6);
    position: absolute;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    @include card();

    min-width: 80vw;
    max-width: 50em;
    min-height: 95vh;
    max-height: 95vh;

    @media (min-width: $widthMobile) {
        min-height: 60vh;
        max-height: 60vh;
    }

    overflow-y: hidden;
}