$trans: .2s;

$border-radius: .3rem;
$border-width: .2rem;

$spacing-base: .6rem;

// https://palx.jxnblk.com/ #94D736
$color-main: #0054ab;

$color-border: #3c4134;
$color-border-radio: #314511;
$color-border-radio-selected: #96d636;

$widthMobile: '700px';

@mixin card() {
    border-radius: $border-radius;
    border: $border-width solid $color-border;
    background-color: white;
    padding: $spacing-base;
    background-color: #f6fbed;
}

@mixin input() {
    font-size: 1.2em;
    padding: $spacing-base;
    border-radius: $border-radius;
    border: none;
    box-shadow: 0 0 3px inset #6A6A6A;
    background-color: #f5f9fc;
}