@import '../../scss/variables';


.container {
	position: relative;
}

.image {
	border-radius: $border-radius;
}

.sku {
	position: absolute;
	left: 0;
	bottom: $spacing-base * 2;
	background-color: rgba(white, 0.5);
	width: 100%;
	text-align: center;
	font-size: 1.1em;
	text-transform: uppercase;
	padding: calc($spacing-base/2) 0;
}