@import '../../scss/variables';

.container {
	display: flex;
	justify-content: baseline;
}

.icon {
	margin-right: $spacing-base;
	color: rgba(0,0,0,0.7);
}