@import '../../scss/variables';

.container {
	margin-bottom: $spacing-base;
}

.label {
	display: flex;
}

.input {
	@include input();
	width: calc(100% - $spacing-base * 2);
}

.select {
	@include input();
	width: calc(100%);
}
