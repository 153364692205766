@import '../../scss/variables';

.container {
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	border-radius: $border-radius;
	padding: $spacing-base;
	font-family: monospace;
}
