@import '../../scss/variables';

.container {
	display: flex;
	background-color: white;
	border-radius: $border-radius;
	width: 100%;
}

.input {
	@include input();

	width: 100%;
	border: none;

	background-color: rgba(orange, 0.2);

	&:disabled {
		background-color: grey;
	}

	&:focus {
		outline: none;
	}
}