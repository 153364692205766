@import '../../scss/variables';

.container {
	cursor: pointer;
	background-color: rgba(255, 255, 255, .3);
	padding: $spacing-base;
	margin-bottom: $spacing-base;
	border-radius: $border-radius;
	text-align: center;
	border: .1rem solid rgba(255, 255, 255, .3);
	border-top: none;
	border-left: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1em;
}

.icon {
	color: black;
	margin-right: .5rem;
}

.powermode {
	font-style: italic;
	background-color: rgba(255, 255, 255, .5);
	border-color: rgba($color-main, 0.2);
}

.selected {
	background-color: rgba(255, 255, 255, .9);
	border-color: rgba($color-main, 0.9);
}

.noHandler {
	border-color: rgba(red, 0.9);
}