@import './scss/variables';

body {
	margin: 0;
	font-family: sans-serif;
}

.container {
    display: grid;
    background-color: rgba($color-main, .3);
    grid-template-columns: 2fr 1fr;
    grid-template-rows: calc(100vh - 4em) auto;
    grid-gap: $spacing-base;
}

.development {
    background-color: rgba(#666f58, .3);
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}

// .mainwindow {
// 	display: grid;
// 	grid-template-rows: 1fr 2fr;
// 	grid-gap: $spacing-base;
// 	background-color: rgba(255, 255, 255, 0.3);
// 	border-radius: $border-radius;
// 	overflow-y: auto;
// }

// .sidebar {
// 	display: grid;
// 	grid-template-rows: 2em auto 10em;
// 	grid-gap: $spacing-base;
// 	// background-color: rgba(255, 255, 255, .3);
// 	border-radius: $border-radius;
// }

// .scanstatus {
// 	display: flex;
// 	grid-column: span 2;

// 	form {
// 		width: 100%;
// 	}
// }

// .undefined {
// 	color: grey;
// }

// .error {
// 	color: orange;
// }

// .connected {
// 	color: green;
// }

// .disconnected {
// 	color: red;
// }

// .submitwindow {
// 	display: grid;
// 	grid-template-columns: 5fr 3fr;
// 	grid-gap: $spacing-base * 3;
// }
