@import '../../scss/variables';

// .container {

// }

.product-info-container {
	display: flex;
	gap: $spacing-base;
	padding-bottom: $spacing-base;
	flex-wrap: wrap;
}

.product-info {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: $spacing-base;

	div:nth-of-type(2n -1) {
		font-weight: 600;
	}
}

.product-name {
	font-size: 1.2em;
}

.product-size {
	padding: $spacing-base 0;
}

.productImage {
	min-width: 150px;
	min-height: 150px;
}

.bold {
	font-weight: bold;
}

.formWrapper {
	display: flex;
	gap: 1rem;
}