.container {
	display: flex;
	justify-content: space-around;
	margin-bottom: 1rem;
	flex-wrap: wrap;
	gap: 1rem;
}

.innerContainer {
	display: flex;
	justify-content: center;
}