@import '../../scss/variables';

.container {
    display: flex;
    grid-template-columns: 1fr 4fr 1fr;
}

.input {
	@include input();

	text-align: center;
}

.button {
	width: 100%;
}