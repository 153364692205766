@import '../../scss/variables';

.container {
	display: grid;
	gap: 1rem;

	@media (min-width: $widthMobile) {
		grid-template-columns: 15% auto;
	}
}

.menu {
	display: flex;
	flex-direction: column;
	height: calc(100% - 3rem);

	@media (max-width: $widthMobile){

		button {
			order: -1;
			margin-bottom: $spacing-base;
		}
	}
}

.list {
	padding: 0 0.5rem 0 0;
	margin: 0;
	list-style: none;
	flex-grow: 1;
}

.listItem {
	padding: 0.5rem 0;
	cursor: pointer;
	text-align: center;

	&:not(:last-of-type) {
		border-bottom: 1px solid rgba(0,0,0,.2);
	}

	&:hover {
		background-color: white;
	}
}

.selected {
	font-weight: bold;
}

.page {
	background-color: white;
	overflow-y: auto;
	max-height: 60vh;
	box-shadow: rgba(0,0,0,0.6) 0 0 5px inset ;
	padding: 0 1rem;
	border-radius: $border-radius;
	min-height: 100%;
}
