@import '../../scss/variables.scss';

.container {
	height: 3.41em;
	grid-column: span 2;
	grid-template-columns: 2fr 1fr;
	display: grid;
	gap: $spacing-base;
}

.sub-container {
	display: flex;
}

.status-container {
	display: flex;
}