.container {
	margin-bottom: 1rem;
}

.containerInner {
	display: flex;
	gap: 1rem;
}

.row {
	flex-grow: 1;
}

.sku {
	font-weight: bold;
	font-size: 1.2em;
}

.name {
	margin-bottom: 1rem;
	font-size: 1.2em;
}

.toShipHeading {
	font-weight: bold;
	text-align: center;
}

.toShip {
	font-weight: bold;
	text-align: center;
}
