@import '../../scss/variables';

.container {
	position: relative;
}

.scrollContainer {
	overflow-y: auto;
	max-height: 100%;
}

.loader {
	background-color: rgba(0,0,0,0.3);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;

	div {
		position: absolute;
		width: 1em;
		height: 1em;
		background-color: $color-main;
		top: 50%;
		left: 50%;
		animation: rotate 1s infinite;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
