@import '../../scss/variables';

.container {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: $border-radius;
	padding: $spacing-base;
}

.scrollable {
	max-height: 100%;
	overflow-y: auto;
}

.warning {
	border: 2px solid;
	border-color: rgba(245, 124, 0, 1)
}

.error {
	border: 2px solid;
	border-color: rgb(211, 47, 47);
}
