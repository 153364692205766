.container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0.2rem;
}

.store {
	display: flex;
	justify-content: baseline;
	gap: 0.5rem;
}

.publicId {
	font-weight: bold;
	font-size: 1.5em;
}

.techId {
	font-weight: lighter;
	padding-left: 0.5rem;
	opacity: 0.3;
}

.mageLink {
	margin-bottom: 0.5rem;
}

.customer {
	font-weight: bold;
	margin-bottom: 0.2rem;
}
