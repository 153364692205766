@import '../../scss/variables';

.address {
	display: flex;
	justify-content: flex-end;
}

.productsWrapper {
	margin-top: 1rem;
}

.productsHeader {
	font-weight: bold;
	font-size: 1.2em;
	margin-bottom: 0.5rem;
}

.error {
	text-align: center;
	color: red;
}
.container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0.2rem;
}
